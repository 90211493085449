<template>
    
    <div class="details--container" key="arrangements-details">
        <div class="action--bar">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <router-link to="/arrangementen" tag="a" class="btn btn-secondary btn-icon">
                            <img src=@/assets/img/icons/arrow-left.svg>Terug naar overzicht
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="image--block pos-right">
            <div class="image--block-content col-lg-6 col-md-4">
                <background-image image="boot.jpg" prefix="arrangementen/big/"></background-image>
            </div>
            <div class="container">
                <div class="row section">
                    <div class="col-lg-5 col-md-7">
                        <h3>Kleine rondvaart in de Biesbosch</h3>
                        <p>
                            Deze rondvaart is apart bij te boeken of bij een boeking vanaf 3 nachten via onze website krijgt u deze rondvaart gratis.
                        </p>
                        <p>
                            U vaart door de smalle kreken van nationaal park de Biesbosch met een platbodem met open kuip, zodat u de natuur goed kunt zien.
                            We varen dan vanaf Vissershang via het bovenste gat van het Zand naar het Steurgat en daarna gaan we de smalle kreek ‘de Palingsloot’ in en vervolgens krijgt u een prachtig uitzicht over de Polder Lepelaar, waar vaak hele kolonies trekvogels te vinden zijn en we varen terug langs Jannezand naar de haven.                                                     
                        </p>
                        <p>
                            Tijdens deze tocht verzorgen wij een drankje afhankelijk van het jaargetijde. Duur van deze tocht is 1,5 uur en hij kan zowel in de zomer als de winter gevaren worden afhankelijk van de weersomstandigheden.
                        </p>
                        <p>
                            De kosten zijn € 59,50 met maximaal 4 personen.
                        </p>
                        <router-link to="/reserveren" tag="a" class="btn btn-secondary btn-icon">
                            <img src="@/assets/img/icons/calendar-check.svg" />Nu boeken
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    metaInfo: {
        title: 'Kleine rondvaart door de Biesbosch',
        meta: [
            { vmid: 'description', name: 'description', content: 'Bij een boeking vanaf 7 nachten krijgt u gratis een kleine rondvaart aangeboden door de smalle kreken van nationaal park de Biesbosch met een gids.' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.deoudestoffeerderij.nl/arrangementen/kleine-rondvaart'}
        ]
    }
}
</script>
